import(/* webpackMode: "eager", webpackExports: ["CollectionsProvider"] */ "/Users/yangyang/Repos/boom/apps/web/context/collections.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownProvider"] */ "/Users/yangyang/Repos/boom/apps/web/context/dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EventsProvider"] */ "/Users/yangyang/Repos/boom/apps/web/context/events.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchProvider"] */ "/Users/yangyang/Repos/boom/apps/web/context/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/apps/web/src/components/layouts/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/apps/web/src/components/layouts/MenuMobile.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/apps/web/src/components/layouts/SearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/apps/web/src/components/PriceTrendDisplay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/apps/web/src/layouts/UserMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/apps/web/src/theme/MuiTheme.jsx");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/apps/web/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/Users/yangyang/Repos/boom/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@mui/material/AppBar/index.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/yangyang/Repos/boom/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Public_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"]}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/Users/yangyang/Repos/boom/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"plusJakarta\"}");

import React from 'react';
import Link from 'next/link';

const DumbLink = React.forwardRef(({ children, ...props }: any, ref) => {
  // Add prefetch control for product links
  const isProductLink = props.href?.startsWith('/product/');

  return (
    <Link
      href={props.href}
      ref={ref as any}
      {...props}
      prefetch={isProductLink ? false : undefined} // Disable for product pages, default for others
    >
      {children}
    </Link>
  );
});

DumbLink.displayName = 'DumbLink';

export default DumbLink;
